import Mock from "../mock";

const database = {
  portfolios: [{}, {}],
  information: {
    name: 'Magic Cookie',
    aboutContent: "We optimize business, starting with the break room! We believe making nutrition available during long work hours and emphasizing healthy sleep habits is something that makes the biggest difference in boosting productivity. We are also leading experts in administative optimization. We will look at your management and business processes and use our experience to bring it to the next level.",
    age: 24,
    phone: '',
    nationality: 'American',
    language: 'English',
    email: '',
    address: 'Magic Cookie Group LLC 910 Wolcott Street STE 13 #1020 Waterbury, CT 06705',
    freelanceStatus: 'Available',
    socialLinks: {
      facebook: 'https://facebook.com',
      twitter: 'https://twitter.com',
      pinterest: '',
      behance: '',
      linkedin: '',
      dribbble: '',
      github: 'https://github.com'
    },
    brandImage: '/images/brand-image.jpg',
    aboutImage: '/images/about-image.jpg',
    aboutImageLg: '/images/about-image-lg.jpg',
    cvfile: '/files/empty.pdf'
  },
  services: [
    {
      title: "Sweet Treatment",
      icon: 'color-pallet',
      details: "Get a Magic Cookie specialist to keep your breakroom supplied with all food groups, including homeade cookies! Working a full day takes energy, so we're here to help."
    },
    {
      title: "Administrative Consulting",
      icon: 'code',
      details: "We'll analyze your workflows from top to bottom in your administration. We get the whole team up to speed quick, and we work with thought leaders in application development to get your tech working the way you need."
    }
  ],
  reviews: [],
  skills: [
    {
      title: "Baking",
      value: 100
    },
    {
      title: "Administration",
      value: 100
    },
    {
      title: "Positivity",
      value: 100
    }
  ],
  experience: {
    workingExperience: [
    ],
    educationExperience: [
    ]
  },
  blogs: [
  ],
  contactInfo: {
    phoneNumbers: ['+1-860-420-2402'],
    emailAddress: ['magiccookiegroup@gmail.com'],
    address: 'Magic Cookie Group LLC 910 Wolcott Street STE 13 #1020 Waterbury, CT 06705'
  }
}


Mock.onGet("/api/information").reply(config => {
  const response = database.information;
  return [200, response];
});

Mock.onGet("/api/services").reply(config => {
  const response = database.services;
  return [200, response];
});

Mock.onGet("/api/reviews").reply(config => {
  const response = database.reviews;
  return [200, response];
});

Mock.onGet("/api/skills").reply(config => {
  const response = database.skills;
  return [200, response];
});

Mock.onGet("/api/portfolios").reply(config => {
  const response = database.portfolios;
  return [200, response];
});

Mock.onGet("/api/experience").reply(config => {
  const response = database.experience;
  return [200, response];
});

Mock.onGet("/api/blog").reply(config => {
  const response = database.blogs;
  return [200, response];
});

Mock.onGet("/api/contactinfo").reply(config => {
  const response = database.contactInfo;
  return [200, response];
});
